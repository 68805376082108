import { Box } from "@mui/system";
import React from "react";
import Styled from "styled-components";
import "./Title.css";

const TitleComponent = ({ Title, type }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <TitleSection className={`Background` + type}>{Title}</TitleSection>
    </Box>
  );
};

export default TitleComponent;

const TitleSection = Styled.div`
font-size:30px;
font-weight:1000;
color:white;
display: flex;
align-items: center;
justify-content: center;
text-align:center;
backdrop-filter: blur(10px);
`;
