import axios from "axios";
import Cookies from "universal-cookie";

import { userAtom } from "../../states/Atoms/AuthAtoms";
import { AuthUserInfo } from "../../states/Atoms/AuthRecoil";
import { AppServerIP } from "../../Config/Serverinfo";
import tokenStore from "../../states/Stores/store";

// 1. Recoil 액세스 토큰 값 있는지 검증
// 2. Recoil 액세스 토큰을 헤더에 태워서 각 메서드로 전송
// const userInfo = AuthUserInfo();

const cookies = new Cookies();
let currentConfig = null;

export const IVISAxios = axios.create({
  // baseURL:
  //   process.env.NODE_ENV === "development"
  //     ? "http://3.36.115.7:3002/"
  //     : "http://172.26.4.8:3002/",
  baseURL: AppServerIP,
  //baseURL: "http://localhost:8080",
  headers: {
    // Authorization: userInfo.accessToken,
    Authorization: localStorage.getItem("access_Token"), // 로컬 스토리지 버전
    // Authorization: tokenStore.getState().accessToken, // redux 버전
  },
  withCredentials: true,
  origin: [
    "http://localhost:3000",
    "http://localhost:8080",
    "http://172.26.0.28:8080",
    "http://172.26.1.218:38253",
    "http://52.79.210.53:8080",
    "http://13.125.222.242:38253",
    "https://api.aone-ivis.com:8080/",
    // "http://172.26.4.8:80",
  ],
});

IVISAxios.interceptors.request.use((config) => {
  currentConfig = config;
  return config;
});

IVISAxios.interceptors.response.use(
  // (res, err) => {
  (res) => {
    // console.log(res.status);
    currentConfig = null;
    return res;
  },
  async (err) => {
    // 2021/12/08 해당 프로세스 백으로 넘김.
    //   try {
    //     if (err.response.status === 419) {
    //       // 419시 여기서 !!!!!
    //       console.log("사일구");
    //       const refreshResult = await IVISAxios.get("/refresh", {
    //         headers: {
    //           Authorization: cookies.get("refreshToken"),
    //         },
    //       });
    //       if (refreshResult.status === 200) {
    //         // HTTP ONLY COOKIE !!!!!!!!!!!!!!!!! 11/23일 아침에
    //         localStorage.setItem("access_Token", refreshResult.data.accessToken);
    //         IVISAxios(currentConfig);
    //       } else {
    //         console.log(refreshResult);
    //       }
    //     } else console.log(err.response);
    //   } catch (e) {
    //     console.log(e);
    //   } finally {
    //     currentConfig = null;
    //   }
  }
);

// return res;
